import { Route } from '@angular/router';

import { Step } from 'libs/components/legacy/wizard/models';
import * as fromBaseState from 'libs/infrastructure/base-state';

export function RegistrationStepHandler(stepsRoutes, wizardSteps, mainPath) {
  return function (target: any) {
    target.prototype.getAllSteps = function (payload: {
      valid: boolean;
      numberOfQuestions: boolean;
      noDistrict: boolean;
      stepName: string;
    }) {
      let steps = stepsRoutes;
      if (!payload?.valid || !payload?.numberOfQuestions) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-call
        steps = steps.filter(
          routeObject =>
            routeObject.path !== wizardSteps.GLOBAL_CUSTOM_QUESTIONS
        );
      }
      if (payload?.noDistrict && payload?.stepName) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-call
        steps = steps.filter(
          routeObject => routeObject.path !== payload?.stepName
        );
      }
      // eslint-disable-next-line @typescript-eslint/no-unsafe-return,@typescript-eslint/no-unsafe-call
      return steps.map((routeObject: Route) => ({
        name: routeObject.path,
        nameNice: payload?.noDistrict
          ? routeObject.data.titleGarage
          : routeObject.data.title,
        hideDKExplanation: routeObject.data.hideDKExplanation,
        dkColor: routeObject.data.dkColor
      }));
    };

    target.prototype.isSelectActionAvailable = function (newStepName: string) {
      if (
        /* eslint-disable @typescript-eslint/no-unsafe-call */
        this.isStepBeforeCurrent(newStepName) &&
        this.completedSteps.includes(newStepName)
        /* eslint-enable @typescript-eslint/no-unsafe-call */
      )
        return true;

      // eslint-disable-next-line @typescript-eslint/no-unsafe-return
      return (
        this.currentForm.valid &&
        /* eslint-disable @typescript-eslint/no-unsafe-call */
        !this.isAnyStepBeforeInvalid(newStepName) &&
        this.visitedSteps.includes(newStepName)
        /* eslint-enable @typescript-eslint/no-unsafe-call */
      );
    };

    target.prototype.isAnyStepBeforeInvalid = function (stepName: string) {
      /* eslint-disable @typescript-eslint/no-unsafe-call */
      const foundIndex = this.allSteps.findIndex(
        step => step.name === stepName
      );

      // eslint-disable-next-line @typescript-eslint/no-unsafe-return
      return this.allSteps.some(
        (step, index) => index < foundIndex && !this.form.get(step.name).valid
      );
      /* eslint-enable @typescript-eslint/no-unsafe-call */
    };

    target.prototype.isStepBeforeCurrent = function (stepName: string) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call
      const foundIndex = this.allSteps.findIndex(
        step => step.name === stepName
      );

      return foundIndex < this.currentStepIndex - 1;
    };

    target.prototype.isStepSelectable = function (stepName: string) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-return,@typescript-eslint/no-unsafe-call
      return this.allSteps?.length && this.isSelectActionAvailable(stepName);
    };

    target.prototype.updateSelectable = function () {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-return
      this.allSteps = this.allSteps.map(step => ({
        ...step,
        // eslint-disable-next-line @typescript-eslint/no-unsafe-call
        selectable: this.isStepSelectable(step.name)
      }));
    };

    target.prototype.previousStep = function () {
      const previousStep = this.allSteps[this.currentStepIndex - 1];

      if (!previousStep) return;

      // eslint-disable-next-line @typescript-eslint/no-unsafe-call
      this.navigateToStep(previousStep.name, this.currentStepIndex - 1);
    };

    target.prototype.selectStep = function (step: Step) {
      /* eslint-disable @typescript-eslint/no-unsafe-call */
      const newStepIndex = this.allSteps.findIndex(
        element => element.name === step.name
      );

      if (!this.isSelectActionAvailable(step.name)) return;

      this.navigateToStep(step.name, newStepIndex);
      /* eslint-enable @typescript-eslint/no-unsafe-call */
    };

    target.prototype.showStep = function (name: string) {
      if (
        name === wizardSteps.GLOBAL_CUSTOM_QUESTIONS &&
        this.customQuestions?.length === 0 &&
        this.hierarchicalRootQuestions?.length === 0
      ) {
        return false;
      }
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call
      return this.getStepIndex(name) > -1;
    };

    target.prototype.getStepIndex = function (name: string) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-return
      return this.allSteps
        ? // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-return
          this.allSteps.map(el => el.name).indexOf(name)
        : -1;
    };

    target.prototype.nextStep = function (stepName: string) {
      const nextStep = this.allSteps[Number(this.currentStepIndex) + 1];

      if (!nextStep) return;

      this.completedSteps = [
        // eslint-disable-next-line @typescript-eslint/no-unsafe-call
        ...this.completedSteps.filter(step => step !== stepName),
        stepName
      ];
      this.visitedSteps = [
        // eslint-disable-next-line @typescript-eslint/no-unsafe-call
        ...this.visitedSteps.filter(step => step !== nextStep.name),
        nextStep.name
      ];
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call
      this.navigateToStep(nextStep.name, Number(this.currentStepIndex) + 1);
    };

    target.prototype.navigateToStep = function (
      newStepName: string,
      newStepIndex: number
    ) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call

      this.currentStepIndex = newStepIndex;
      /* eslint-disable @typescript-eslint/no-unsafe-call */

      const projectToken = this.router?.parseUrl(this.router?.url)?.queryParams
        ?.project as string;
      // project token is appended to the first step from login
      // then is added to each step via query params
      this.store.dispatch(
        new fromBaseState.Go({
          path: [mainPath, newStepName],
          query: { project: projectToken }
        })
      );

      this.updateSelectable();
      this.preserveStepsData();
      /* eslint-enable @typescript-eslint/no-unsafe-call */
    };

    target.prototype.preserveStepsData = function () {
      const { currentStepIndex, completedSteps, visitedSteps } = this;
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call
      this.storageService.setSteps({
        currentStepIndex,
        completedSteps,
        visitedSteps
      });
    };

    target.prototype.checkRouteValidity = function () {
      /* eslint-disable @typescript-eslint/no-unsafe-call */
      let stepPath = this.router?.url.split('/').pop();
      if (stepPath?.includes(`project`)) {
        // remove part of stepPath after '?' so steps are matching steps from allSteps
        stepPath = stepPath.split('?')[0];
      }

      const stepIndex = this.allSteps.findIndex(step => step.name === stepPath);

      if (!stepIndex || stepIndex < 0) return;

      const prevStep = this.allSteps[stepIndex - 1].name;

      if (!this.completedSteps.includes(prevStep)) {
        this.navigateToStep(this.allSteps[0].name, 0);
      }
      /* eslint-enable @typescript-eslint/no-unsafe-call */
    };
  };
}
